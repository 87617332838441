export enum OfferType {
  "ARTICLE",
  "GOODWILLREWARD",
  "PRIZEDRAW",
  "REWARD",
  "INSTANT_WIN",
  "BANNER",
  "LUCKY_DIP",
  "VISIT_TO_WIN"
}
